<template>
  <v-container>
    <v-row>
      <v-col><h2 class="text-center text-h3">Öffnungszeiten</h2></v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center text-body-1" :class="day===1?'font-weight-bold':''">Montag:
          {{ nextSpecialDays[1] == false ? times[1] : nextSpecialDays[1] }}
        </div>
        <div class="text-center text-body-1" :class="day===2?'font-weight-bold':''">Dienstag:
          {{ nextSpecialDays[2] == false ? times[2] : nextSpecialDays[2] }}
        </div>
        <div class="text-center text-body-1" :class="day===3?'font-weight-bold':''">Mittwoch:
          {{ nextSpecialDays[3] == false ? times[3] : nextSpecialDays[3] }}
        </div>
        <div class="text-center text-body-1" :class="day===4?'font-weight-bold':''">Donnerstag:
          {{ nextSpecialDays[4] == false ? times[4] : nextSpecialDays[4] }}
        </div>
        <div class="text-center text-body-1" :class="day===5?'font-weight-bold':''">Freitag:
          {{ nextSpecialDays[5] == false ? times[5] : nextSpecialDays[5] }}
        </div>
        <div class="text-center text-body-1" :class="day===6?'font-weight-bold':''">Samstag:
          {{ nextSpecialDays[6] == false ? times[6] : nextSpecialDays[6] }}
        </div>
        <div class="text-center text-body-1" :class="day===0?'font-weight-bold':''">Sonntag:
          {{ nextSpecialDays[0] == false ? times[0] : nextSpecialDays[0] }}
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

Date.prototype.getWeekNumber = function () {
  let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  let dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
};

export default {
  name: "OpeningTimes",
  data() {
    return {
      day: new Date().getDay(),
      week: new Date().getWeekNumber(),
      times: {
        0: "09:00 - 18:00",// "09:00 - 18:00",
        1: "Ruhetag",
        2: "Ruhetag",
        3: "11:00 – 18:00",//3: "11:00 – 18:00",
        4: "11:00 – 18:00",//4: "11:00 – 18:00",
        5: "09:00 – 18:00",//"09:00 – 18:00",
        6: "09:00 – 18:00",//"09:00 – 18:00"
      },
      specialDays: [
        {
          date: new Date('2024-12-06'),
          time: "ab 16:00 Uhr",
          name: "Glühweinabend"
        },
        {
          date: new Date('2024-12-07'),
          time: "ab 12:00 Uhr",
          name: "Weihnachtsmarkt"
        },
        {
          date: new Date('2024-12-08'),
          time: "ab 12:00 Uhr",
          name: "Weihnachtsmarkt"
        },
        {
          date: new Date('2023-04-06'),
          time: "11:00 - 18:00",
          name: "Gründonnerstag"
        },
        {
          date: new Date('2023-04-07'),
          time: "09:00 - 18:00",
          name: "Karfreitag"
        },
        {
          date: new Date('2023-04-09'),
          time: "09:00 - 18:00",
          name: "Ostersonntag"
        },
        {
          date: new Date('2023-04-10'),
          time: "09:00 - 18:00",
          name: "Ostermontag"
        },
        {
          date: new Date('2021-06-03'),
          time: "10:00 - 18:00",
          name: "Fronleichnam"
        },
        {
          date: new Date('2022-10-03'),
          time: "09:00 - 18:00",
          name: "Tag der Deutschen Einheit"
        },
        {
          date: new Date("2023-12-20"),
          time: "Geschlossen",
          name: ""
        },
        {
          date: new Date("2023-12-21"),
          time: "Geschlossen",
          name: ""
        },
        {
          date: new Date("2023-12-22"),
          time: "Geschlossen",
          name: ""
        },
        {
          date: new Date("2023-12-23"),
          time: "Geschlossen",
          name: ""
        },
        {
          date: new Date("2023-12-24"),
          time: "Geschlossen",
          name: "Heiligabend"
        },
        {
          date: new Date("2023-12-25"),
          time: "Geschlossen",
          name: "1. Weihnachtsfeiertag"
        },
        {
          date: new Date("2023-12-26"),
          time: "Geschlossen",
          name: "2. Weihnachtsfeiertag"
        },
        {
          date: new Date("2023-12-27"),
          time: "11:00 – 18:00",
          name: ""
        },
        {
          date: new Date("2023-12-28"),
          time: "11:00 – 18:00",
          name: ""
        },
        {
          date: new Date("2023-12-29"),
          time: "09:00 – 18:00",
          name: ""
        },
        {
          date: new Date("2023-12-30"),
          time: "09:00 – 18:00",
          name: ""
        },
        {
          date: new Date("2023-12-31"),
          time: "09:00 – 16:00",
          name: "Silvester"
        },
        {
          date: new Date("2024-01-01"),
          time: "10:00 – 18:00",
          name: "Neujahr"
        },
        {
          date: new Date("2024-02-14"),
          time: "11:00 – Open End",
          name: "Aschermittwoch mit Fischbuffet (ab 17 Uhr)"
        }

      ],
      holidays: [
        {
          from: new Date("2024-11-04"),
          to: new Date("2024-11-26"),
          time: "Geschlossen",
          name: "Betriebsferien"
        },
        {
          from: new Date("2024-02-07"),
          to: new Date("2024-02-13"),
          time: "Geschlossen",
          name: "Brauchtumspflege"
        }
      ]
    }
  },
  computed: {
    nextSpecialDays() {
      let specialDays = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false};
      const today = new Date();
      this.specialDays.forEach(value => {

        let diffTime = value.date - today;
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (0 <= diffDays && diffDays <= 6) {
          specialDays[value.date.getDay()] = (value.time != null ? value.time : this.times[value.date.getDay()]) + (value.name?" (" + value.name + ")":"");
        }
      })


      this.holidays.forEach(value => {
        for(let i=0;i<7;i++) {
          if(today >= value.from && today <= value.to) {
            specialDays[today.getDay()] = value.time;
          }
          today.setDate(today.getDate()+1)
        }
      });



      return specialDays;
    },
    specialDayList() {

      return this.specialDays.map(specialDay => { return specialDay.name + " " + specialDay.date.getDay()+"."+specialDay.date.getMonth()+"."+specialDay.date.getFullYear() + specialDay.time }).join(",");
    }
  }
}
</script>

<style scoped>
.rotate-left {
  margin-left: 10px;
  margin-right: 11px;
  transform: rotate(353deg);
}

.rotate-right {
  transform: rotate(7deg);
}
</style>